import React from "react"
import dynamic from "next/dynamic"
import { useDispatch, useSelector } from "react-redux"

import * as ga from "@/ga"

import { Formik } from "formik"
import * as Yup from "yup"
import { Form as FormikForm } from "formik"
import { Modal, Row } from "react-bootstrap"

import PropertyRefField from "@/fields/propertyRefField"
import NameField from "@/fields/nameField"
import EmailField from "@/fields/emailField"
import PhoneField from "@/fields/phoneField"
import MessageField from "@/fields/messageField"
import Recaptcha from "@/fields/recaptcha"
import SubmitButton from "@/fields/submitButton"

const SuccessToast = dynamic(() => import("@/toasts/successToast"))
const ErrorToast = dynamic(() => import("@/toasts/errorToast"))

import {
    toggleRequestViewingModal,
    resetRequestViewingModal,
    sendRequestViewingData,
} from "@/slices/requestViewingModalSlice"

function RequestViewingModal() {
    const dispatch = useDispatch()

    const { cookieConsent } = useSelector((state) => state.cookie)

    const generateLead = () => {
        if (cookieConsent === "true") {
            ga.event({
                action: "generate_lead",
            })
        }
    }

    // The selector to retrieve whether the viewing modal should be
    // shown or hidden from state.
    const { status, showRequestViewingModal, property } = useSelector(
        (state) => state.requestViewingModal,
    )

    // A regular expression that can detect a small number of fake mobile
    // numbers that will be used for validation (doesn't allow chars).
    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    // The schema used for validation of the request viewing form input.
    const RequestViewingSchema = Yup.object().shape({
        propertyRef: Yup.string()
            .matches(property.property_ref)
            .required("This field is required."),
        fullName: Yup.string().required("This field is required."),
        email: Yup.string()
            .email("Must be a valid email.")
            .required("This field is required."),
        phone: Yup.string().matches(
            phoneRegExp,
            "Must be a valid phone number (digits only).",
        ),
        captcha: Yup.boolean().oneOf(
            [true],
            "Please confirm you are not a robot.",
        ),
    })

    return (
        <>
            <SuccessToast
                status={status}
                resetModal={() => {
                    dispatch(resetRequestViewingModal())
                    generateLead()
                }}
            />
            <ErrorToast
                status={status}
                resetModal={() => dispatch(resetRequestViewingModal())}
            />
            {/* Request Viewing Form Modal */}
            <Modal
                show={showRequestViewingModal}
                onHide={() => dispatch(toggleRequestViewingModal(false))}
                fullscreen="lg"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Modal.Title>
                        <h2 className="text-center">Request a viewing</h2>
                        <p className="text-center">
                            Simply enter your details and a member of our team
                            will be in touch shortly to arrange your viewing.
                        </p>
                    </Modal.Title>

                    {/* Request Viewing Form */}
                    <Formik
                        initialValues={{
                            propertyRef: property.property_ref,
                            transTypeId: property.trans_type_id,
                            address: property.display_address,
                            fullName: "",
                            email: "",
                            phone: "",
                            captcha: false,
                            message: "",
                        }}
                        validationSchema={RequestViewingSchema}
                        // Send the form data to the backend
                        onSubmit={async (values) => {
                            await dispatch(sendRequestViewingData(values))
                        }}
                    >
                        {(formik) => (
                            <FormikForm>
                                <PropertyRefField formik={formik} />

                                <Row>
                                    <NameField formik={formik} />

                                    <EmailField formik={formik} />
                                    <PhoneField formik={formik} />

                                    <MessageField
                                        optional={true}
                                        formik={formik}
                                    />
                                </Row>
                                <div className="d-none d-sm-inline">
                                    <Recaptcha formik={formik} size="normal" />
                                </div>
                                <div className="d-sm-none">
                                    <Recaptcha formik={formik} size="compact" />
                                </div>
                                <SubmitButton
                                    submit_message="Send request"
                                    status={status}
                                    formik={formik}
                                />
                            </FormikForm>
                            // Request Viewing Form End
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            {/* Request Viewing Form Modal End */}
        </>
    )
}

export default RequestViewingModal
